<template>
  <div class="context_container" :style="{ height: height - 5 + 'px' }">
    <el-row class="manage_title">
      <el-col :span="24" class="manage_title_content">


        <div class="manage_top_area"> 
          <div v-if="!isCollapse" class="corporate_name">
            威视科技-VisionTec
          </div>
          <div v-if="isCollapse" class="corporate_name_develop">
            SASS
          </div>
          <div class="menu_fold">
            <el-icon size="18pt" @click="
              () => {
                isCollapse = !isCollapse;
                if (isCollapse) {
                  CollapseWidth = 56;
                } else {
                  CollapseWidth = 256;
                }
              }
            ">
              <Fold v-if="!isCollapse" />
              <Expand v-if="isCollapse" />
            </el-icon>
            <el-icon size="18pt" @click="RefreshTablePage">
              <RefreshRight size="25" />
            </el-icon>
          </div>
          <div class="manage_user">
            <div v-if="IsVerificationLogin" class="manage_user_login">
              <el-avatar class="avatar" :size="30"
                src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" />

              <el-dropdown class="avatar">
                <span class="el-dropdown-link">
                  {{ UserName }}
                  <el-icon class="el-icon--right">
                    <arrow-down />
                  </el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <div class="dropdown_item ">
                      <el-dropdown-item v-if="IsVerificationLogin" @click="LoginOut"> 退出登录</el-dropdown-item>
                    </div>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>


            </div>
            <div v-if="!IsVerificationLogin">
              <router-link to="/" target="view_window"> 登录 </router-link>
            </div>
          </div>
        </div>



      </el-col>

    </el-row>
    <div :height="height - 55 + 'px'" class="content_area">
      <div :style="{
        height: height - 55 + 'px',
        width: CollapseWidth + 'px',
      }">
        <el-scrollbar :height="height - 75 + 'px'">
          <el-menu mode="vertical" :collapse="isCollapse" ref="menu" active-text-color="#ffd04b"
            background-color="#545c64" class="el-menu-vertical-demo" text-color="#fff" :style="{
              height: height - 75 + 'px',
            }">
            <el-sub-menu :index="menu.Id" v-for="(menu, menuindex) in ManagesMenus" :key="menuindex">
              <template #title>
                <el-icon>
                  <Menu />
                </el-icon>
                <span> {{ menu.Name }} </span>
              </template>
              <el-menu-item v-for="(item, index) in menu.Items" :key="index" :index="item.Id" @click="OpenPage(item)">
                <el-icon style="color: #fff; font-size: 20px">
                  <Epand />
                </el-icon>
                <el-icon>
                  <expand />
                </el-icon>

                {{ item.Name }}</el-menu-item>
            </el-sub-menu>
          </el-menu>
        </el-scrollbar>
      </div>

      <div :style="{
        height: height - 75 + 'px',
        width: width - CollapseWidth + 'px',
      }">
        <el-row>
          <el-col :span="24">
            <div class="tab_area">
              <div class="tab_area_plan">
                <!-- <el-tabs @tab-remove="RemoveTab" v-model="TabName" closable type="border-card">
                  <el-tab-pane v-for="(item, index) in tabUrls" :key="index" :label="item.Name" :name="item.Name">
                    <iframe class="page_iframe" :src="item.Url" width="100%" :height="height - 150 + 'px'"
                      frameborder="0" scrolling="auto"></iframe>
                  </el-tab-pane>
                </el-tabs> -->
                <el-tabs @tab-remove="RemoveTab" v-model="TabName" closable type="border-card">
                  <el-tab-pane v-for="(item, index) in tabUrls" :key="index" :label="item.Name" :name="item.Name">
                    <template #label>
                      <span class="custom-tabs-label">

                        <span style="float: left; margin-left:10px">{{ item.Name }}</span>
                      </span>
                    </template>
                    <iframe class="page_iframe" :src="item.Url" width="100%" :height="height - 150 + 'px'" frameborder="0"
                      scrolling="auto"></iframe>
                  </el-tab-pane>
                </el-tabs>

              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script  >
import axios from "../../../commons/AxiosMethod.js";
import {
  Menu,
  Expand,
  Avatar,
  Wallet,
  Download,
  Delete,
  ArrowDown,
  Fold,
  RefreshRight
} from "@element-plus/icons-vue";
import { ref } from "vue";

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      CollapseWidth: 56,
      isCollapse: false,
      value: "",
      menuindex: "1",
      UserName: "", //用户名称
      IsVerificationLogin: false,
      height: window.innerHeight,
      width: window.innerWidth,
      Host: location.host,
      TabName: "默认页面",
      ManagesMenus: [
        {
          Name: "商户管理",
          Id: 0,
          Items: [
            { Name: "公司管理", Id: "0-1", Url: "sysCompanysManage" },
            { Name: "商户管理", Id: "0-2", Url: "sysStoreManage" },
            { Name: "商户账号管理", Id: "0-3", Url: "accountManage" }, 
          ],
        },
        {
          Name: "平台管理",
          Id: 1,
          Items: [ 
            { Name: "角色管理", Id: "1-2-1", Url: "role" },
            { Name: "系统配置", Id: "1-3", Url: "Configurations" },
            { Name: "语言管理", Id: "1-4", Url: "languageManages" },
            { Name: "定价管理", Id: "1-5", Url: "purchaseMoney" },
            { Name: "系统公告", Id: "1-6", Url: "manageNotices" }, 
            { Name: "关于我们", Id: "10-1", Url: "manageAboutus" }, 
          ],
        }, 
      ],
      DefaultHome: {
        Name: "首页",
        Id: 511,
        Url: "AdminHome",
      },

      tabUrls: [], // 页面集合
      AccountModel: {
        Account: "admin",
        Password: "admin",
        Checked: false,
      },
    };
  },
  name: "App",
  mounted() {
    this.OpenPage(this.DefaultHome);
    this.VerificationLogin();

    this.UserName = localStorage.getItem("User_Name");
    this.UserImage = localStorage.getItem("User_Image");

    if (this.isCollapse) {
      this.CollapseWidth = 56;
    } else {
      this.CollapseWidth = 256;
    }

  },
  components: {
    Menu,
    Expand,
    Avatar,
    Wallet,
    Download,
    Delete,
    ArrowDown,
    Fold,
    Expand, RefreshRight
  },
  methods: {

    //刷新tabs page
    RefreshTablePage() {

 

    },

    LoginOut() {
      localStorage.setItem("Authentication_Header", "out");
      localStorage.setItem("User_Name", "");
      localStorage.setItem("User_Image", "");
      this.$router.push({
        path: "/",
      });
    },

    //验证登录
    VerificationLogin() {
      var bearer = localStorage.getItem("Authentication_Header");
      if (bearer != "" || bearer != null || bearer.length > 15) {
        axios.apiMethod(
          "/Home/VerificationLogin",
          "get",
          {},
          (s) => {
            this.IsVerificationLogin = s.Code == 200;
          },
          (d) => {
            alert("ss");
          }
        );
      }
    },
    RemoveTab(name) {
      if (name == "可视化") return;

      for (let i = 0; i < this.tabUrls.length; i++) {
        if (this.tabUrls[i].Name == name) {
          if (this.tabUrls.length >= i - 1) {
            this.TabName = this.tabUrls[i - 1].Name;
          } else {
            this.TabName = this.DefaultHome.Name;
          }

          this.tabUrls.splice(i, 1);
        }
      }
    },
    OpenPage(item) {
      for (let i = 0; i < this.tabUrls.length; i++) {
        if (this.tabUrls[i].Id == item.Id) {
          this.TabName = this.tabUrls[i].Name;
          return;
        }
      }

      this.tabUrls.push(item);
      this.TabName = item.Name;
    },
  },
};
</script>

<style lang="less" scoped>
.manage_title {
  width: 100%;
  background: rgb(67, 74, 80);

  .manage_title_content {
    .login_name_content {
      width: 120px;
      background-color: #fff;
      height: 40px;
      line-height: 40px;

      flex: 1;
      margin-left: auto;
    }
  }
}

.context_container {
  background-color: rgb(255, 255, 255);
}

.page_iframe {
  min-height: 600px;
}

.tab_area {
  display: flex;

  .tab_area_plan {
    width: 100%;
  }
}

.menu_list {
  overflow: auto;
}

.el-dropdown-link {
  text-align: center;
  font-weight: 700;
}

.content_area {
  width: 100%;
  display: flex;
}


.manage_top_area {
  height: 60px;
  width: 100%;
  background-color: #3a3c3d;
  display: flex;
  justify-content: flex-start;
  color: #eee;

  .corporate_name {
    width: 253px;
    height: 60px;
    font-size: 15pt;
    line-height: 60px;
    text-align: center;


  }

  .corporate_name_develop {
    width: 56px;
    height: 60px;
    font-size: 9pt;
    line-height: 60px;
    text-align: center;
    border-right: 1px solid rgb(80, 79, 79);
  }

  .menu_fold {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    width: 100px;
    height: 60px;
    font-size: 15pt;
    line-height: 60px;
    text-align: center;
    border-left: 0.1px solid #fff;
    align-items: center; // 交叉轴方向，默认垂直方向
  }


  .manage_user {

    text-align: center;
    height: 60px;
    line-height: 60px;
    flex: 1;
    display: flex;
    justify-content: flex-end;


    .manage_user_login {
      line-height: 60px;
      background-color: #3a3c3d;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;

      .avatar {
        margin-left: 5px;
        color: #fff;
      }

    }

  }

}

.custom-tabs-label {
  line-height: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
</style>
